import * as React from 'react'
import cn from 'classnames'
import { ColourVariant } from '../../../types/colour-variant'
import styles from './Banner.module.scss'
import { CloseOutline } from '@easy-eva-icons/react'

export type BannerProps = {
  /**
   * Content to put inside the banner, this will be centered vertically and horizontally by default. This is required since a banner without content would be a bit pointless
   * @example <Banner>Hooray!</Banner>
   */
  children: React.ReactNode
  /**
   * The variant to use for the banner, this will determine the text and background colour, success is green, info is blue, warning is amber and danger is red.
   * @example <Banner variant="success" />
   */
  variant: ColourVariant
  /**
   * Optional callback for dismissing a banner. If passed the close icon will show in the banner. This is useful for storing display criteria state elsewhere such as local storage.
   */
  onDismiss?: () => void
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

/**
 * For global messaging that applies to the entirety of the page/screen it’s shown on. It may also be used for service level messaging, ie trial length, upcoming outage, etc. Use sparingly.
 */
export const Banner: React.VFC<BannerProps> = (props) => {
  const { children, variant, className, onDismiss, ...restProps } = props

  const content = React.useMemo((): React.ReactNode => {
    if (!onDismiss) return children

    return (
      <>
        <div className={styles.leftContent} />
        <div>{children}</div>
        <div className={styles.rightContent}>
          <button type="button" title="Dismiss banner" onClick={onDismiss}>
            <CloseOutline />
          </button>
        </div>
      </>
    )
  }, [children, onDismiss])

  return (
    <div
      className={cn(styles.banner, styles[variant], className)}
      {...restProps}
    >
      {content}
    </div>
  )
}
